<template>
    <v-layout column style="margin-top: 20px;">
        <v-tabs color="secondary"
                v-model="selectedTab"
                active-class="font-weight-bold">
            <v-tab class="subtitle-1"
                   v-for="t in tabs"
                   :key="t.to.name"
                   @click="open(t.to)"
                   style="width: 240px;">
                <label>{{t.title}}</label>
            </v-tab>
        </v-tabs>
        <router-view @setLoading="setLoading($event)" :key="$route.name"/>
    </v-layout>
</template>

<script>
    export default {
        name: "StatisticsList",
        data: () => ({
            selectedTab: null,
            tabs: [
                {title: 'Запросы по авто', to: {name: 'statisticsByCar'}},
                {title: 'Запросы по странам', to: {name: 'statisticsByCountry'}},
                {title: 'Разборки по странам', to: {name: 'statisticsByOffers'}}
            ],
            tabIndexes: {
                'statisticsByCar': 0,
                'statisticsByCountry': 1
            }
        }),
        methods: {
            open(route) {
                console.log(route)
                this.$router.history.push(route)
            },
        },
        created() {
            this.selectedTab = this.tabs.map(v => v.to.name).indexOf(this.$route.name)
        }
    }
</script>

<style scoped>

</style>