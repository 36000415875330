<template>
    <v-layout class="content-layout" column>
        <label class="secondary--text display-1 font-weight-bold">Статистика</label>
        <statistics-list/>
    </v-layout>
</template>

<script>
    import StatisticsList from "../components/statistics/StatisticsList";

    export default {
        name: "StatisticsView",
        components: {StatisticsList}
    }
</script>

<style scoped>

</style>